import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import CustomHelmet from "../components/custom-helmet"
import "./contact-us.scss"

export default function ContactUs() {
  return (
    <>
      <CustomHelmet title={"Contact Us - Lunta Labs"} />
      <Header theme="black" />
      <div className="contact-us">
        <h1>Contact Us</h1>
        <div className="contact-us__inner">
          asiakaspalvelu@lunta.fi <br />
          050 428 176
        </div>
      </div>
      <Footer />
    </>
  )
}
